import { CircularProgress, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import {
  db,
  ogCollection,
  query,
  where,
  onSnapshot,
} from "../../services/firebase";
import { useStore } from "../../mobx";

// const key = generateLicense({
//   expiryDate: new Date(3001, 0, 0, 0, 0, 0, 0),
//   licensingModel: "perpetual",
//   orderNumber: "12345",
//   scope: "pro",
// });

LicenseInfo.setLicenseKey(
  "5cfaf6f61f1169c780262c252c07e983Tz0xMjM0NSxFPTMyNTM1MTQ3NjAwMDAwLFM9cHJvLExNPXBlcnBldHVhbCxLVj0y"
);

export default observer(function ReportsTable() {
  const { team } = useStore();
  const [loading, setLoading] = useState(true);

  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (!team.loading) {
      const q = query(
        ogCollection(db, "reports"),
        where("teamId", "==", team.id)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const reports = [];

        querySnapshot.forEach((doc) => {
          reports.push(doc.data());
        });

        setReports(reports);
        setLoading(false);
      });

      return unsubscribe;
    }
  }, [team.loading, team.id]);

  const rows = [];

  for (const [doc, index] of reports.map((v, i) => [v, i])) {
    const rowObject = {
      ...doc.report,
      id: index,
      created: doc.created.toDate().toISOString(),
    };

    rows.push(rowObject);
  }

  return (
    <>
      <Paper
        sx={{
          borderRadius: 0,
          height: "100%",
          width: "100%",

          // px: 2,
          // pb: 2,
          // border: "1px solid red",
          overflow: "overlay",
          justifyContent: "center",
          //   alignItems: "center",
          display: "flex",
          //
        }}
      >
        {loading ? (
          <CircularProgress sx={{ mt: 17 }} />
        ) : (
          <Box sx={{ height: "100%", width: "100%" }}>
            <DataGrid
              columns={[
                {
                  field: "created",
                  headerName: "Created",
                  flex: 1,
                  minWidth: 150,
                },
                {
                  field: "selection",
                  headerName: "Selection",
                  flex: 1,
                  minWidth: 150,
                },
                {
                  field: "description",
                  headerName: "Description",
                  flex: 1,
                  minWidth: 150,
                },
                {
                  field: "details",
                  headerName: "Details",
                  flex: 1,
                  minWidth: 150,
                },
              ]}
              rows={rows}
              sx={{ width: "100%", height: "100%" }}
            />
          </Box>
        )}
      </Paper>
    </>
  );
});
