import { Button, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/system";

import { store } from "../../mobx";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";

import { SubsectionStack } from "./SubsectionStack";

export const Socials = observer(function Socials({
  title = "First Month's Free!",
  titleProps,
  spacing,
}) {
  const navigate = useNavigate();

  const { isPhone } = store.mediaBreakpoint;

  const items = [
    {
      Icon: Facebook,
      src: "https://www.facebook.com/profile.php?id=100054328218293",
    },
    {
      Icon: Twitter,
      src: "https://www.facebook.com/profile.php?id=100054328218293",
    },
    {
      Icon: Instagram,
      src: "https://www.facebook.com/profile.php?id=100054328218293",
    },
  ];

  return (
    <SubsectionStack title={title} titleProps={titleProps} spacing={spacing}>
      <Stack spacing={isPhone ? 3 : 3.5}>
        <Button
          variant="contained"
          color="secondary"
          sx={(theme) => ({
            bgcolor: "black",
            color: "white",
            fontFamily: "PermanentMarker",
            fontSize: isPhone ? 21 : 23,
            letterSpacing: 4,
            borderRadius: 4,
            border: "1px solid",
            borderColor: "secondary.main",
          })}
          onClick={() => navigate("/signup")}
        >
          Sign up
        </Button>
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ px: "20%" }}
        >
          {items.map(({ Icon, src }, index) => (
            <IconButton key={index} component="a" href={src} target="_blank">
              <Icon
                sx={
                  isPhone
                    ? { height: 32, width: 32 }
                    : { height: 36, width: 36 }
                }
              />
            </IconButton>
          ))}
        </Stack>
      </Stack>
    </SubsectionStack>
  );
});
