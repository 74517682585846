import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import AddTeamMemberList from "../../components/Lists/AddTeamMemberList";

import SingleCardPage from "../../components/Page/SingleCardPage";

export default observer(function Signup() {
  return (
    <>
      <SingleCardPage
        cardHeaderProps={{
          title: "Team",
        }}
        cardContentChildren={
          <Stack spacing={4}>
            <AddTeamMemberList />
          </Stack>
        }
      />
    </>
  );
});
