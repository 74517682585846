import { Edit, VerifiedUserOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Badge,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useState } from "react";

import { useStore } from "../../../mobx";
import { stringToHsl } from "../../../utilities";

export const TeamMemberListItem = observer(function TeamMemberListItem({
  member: { name, email, phoneNumber, onboarded },
  editAction,
}) {
  return (
    <ListItem sx={{ px: 0 }}>
      <ListItemAvatar sx={{ alignSelf: "flex-start", pt: 1, mr: 1 }}>
        <Badge
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          badgeContent={
            onboarded && (
              <Tooltip title="Onboarded">
                <VerifiedUserOutlined
                  sx={{
                    fill: "#1DA1F2",
                  }}
                />
              </Tooltip>
            )
          }
        >
          <Avatar sx={{ bgcolor: stringToHsl(name) }}>{name[0]}</Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          <>
            <Typography component="span" sx={{ display: "block" }}>
              {phoneNumber || "Phone Number: none"}
            </Typography>
            <Typography component="span" sx={{ display: "block" }}>
              {email || "Email: none"}
            </Typography>
          </>
        }
      />

      <ListItemSecondaryAction sx={{ justifySelf: "center" }}>
        <IconButton onClick={editAction}>
          <Edit />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
});

export const TeamMemberListItemTeamOwner = observer(
  function TeamMemberListItemTeamOwner({
    index,
    member: { name, email, phoneNumber },
    member,
  }) {
    const { team, dialog, snackbar } = useStore();

    return (
      <TeamMemberListItem
        member={member}
        editAction={() => {
          dialog.set.dialog({
            Content: () => {
              const [confirmRemoval, setConfirmRemoval] = useState(false);
              const [nameLocal, setNameLocal] = useState(name || "");
              const [emailLocal] = useState(email || "");

              const [phoneNumberLocal] = useState(phoneNumber || "");

              return (
                <>
                  <DialogTitle>
                    {confirmRemoval ? "Remove Team Member" : "Edit Team Member"}
                  </DialogTitle>
                  <DialogContent>
                    {confirmRemoval ? (
                      <Typography>
                        Are you sure you want to remove {name}?
                      </Typography>
                    ) : (
                      <Stack spacing={3}>
                        <Stack spacing={1}>
                          <Typography>Name</Typography>
                          <TextField
                            value={nameLocal}
                            onChange={({ target: { value } }) => {
                              setNameLocal(value);
                            }}
                            sx={{ width: "100%" }}
                          />
                        </Stack>
                      </Stack>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color={confirmRemoval ? undefined : "error"}
                      onClick={() => {
                        setConfirmRemoval(!confirmRemoval);
                      }}
                    >
                      {confirmRemoval ? "Cancel" : "Remove"}
                    </Button>
                    <Button
                      color={confirmRemoval ? "error" : "primary"}
                      variant="contained"
                      onClick={() => {
                        dialog.toggle();

                        const phoneNumberChanged =
                          phoneNumber !== phoneNumberLocal;

                        if (confirmRemoval) {
                          team.removeMember(index, {
                            updateDb: true,
                          });
                          snackbar.set.message(
                            `${nameLocal} was removed from your team`
                          );
                        } else {
                          team.editMember(
                            index,
                            {
                              ...member,
                              name: nameLocal,
                              email: emailLocal,
                              // phoneNumber: phoneNumberLocal,
                              // let the backend know on invites that special resetting of things has to occur
                              // we probably only want to deal with the emails, because the onboarding flow should have the user
                              // put in their phone number
                              ...(phoneNumberChanged && { phoneNumberChanged }),
                            },
                            {
                              updateDb: true,
                            }
                          );
                        }
                      }}
                    >
                      {confirmRemoval ? "Remove" : "Save"}
                    </Button>
                  </DialogActions>
                </>
              );
            },
          });
          dialog.toggle();
        }}
      />
    );
  }
);
