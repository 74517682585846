import { makeAutoObservable, reaction } from "mobx";
import {
  auth,
  firestore,
  db,
  ogCollection,
  query,
  where,
  onSnapshot,
  documentId,
} from "../services/firebase";

import {
  signInAnonymously,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

import { setCookie } from "../utilities/cookies";
import { fetchFromApiServer } from "../services/api";

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  login_hint: "user@example.com",
});
//

export default class User {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);

    auth.onAuthStateChanged(async (session) => {
      this.reset();

      if (session === null) {
        signInAnonymously(auth);
        return;
      }

      // update cookie
      setCookie({ key: "firebaseJwt", value: session.accessToken });

      this.set.session(session);
    });

    reaction(
      () => {
        return this.session.uid;
      },
      (uid) => {
        if (!uid) {
          return;
        }
        // unsub previous listeners
        for (const dependency of this.dependencies) {
          dependency();
        }

        const q = query(
          ogCollection(db, "users"),
          where(documentId(), "==", this.session.uid)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const profile = doc.data();
            this.set.profile(profile);
          });

          this.set.loaded(true);
        });

        this.set.dependencies([...this.dependencies, unsubscribe]);
      }
    );
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }

    for (const dependency of this.dependencies) {
      dependency();
    }
  }

  set = {
    dependencies: (dependencies = []) => {
      this.dependencies = dependencies;
    },
    session: (session = {}) => {
      const { email, uid, isAnonymous, photoURL, accessToken } = session;

      this.session = { email, uid, isAnonymous, photoURL, accessToken };
    },
    profile: (
      profile = {
        teams: [],
      }
    ) => {
      this.profile = profile;
    },
    loaded: (loaded = false) => {
      this.loaded = loaded;
    },
  };

  setProfile = (profile = {}) => {
    return firestore.doc.set(
      {
        path: `users/${this.session.uid}`,
        data: profile,
      },
      {
        merge: true,
      }
    );
  };

  authWithGoogle = async () => {
    try {
      const { user } = await signInWithPopup(auth, provider);

      this.set.session(user);
      this.setProfile({
        signedIn: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
  signOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  get subscriptionEnd() {
    return this.profile?.subscriptionPeriodEnd?.toDate();
  }

  // get subscriptionDaysRemaining(){

  //   const endTime = this.subscriptionEnd.getTime() ?? 0

  //   const nowTime = new Date().getTime()

  //   const timeRemaining = endTime - nowTime

  //   return
  // }

  get subscriptionEndString() {
    if (!this.subscriptionEnd) {
      return "";
    }

    return `${this.subscriptionEnd.toLocaleDateString()} at ${this.subscriptionEnd.toLocaleTimeString()}`;
  }
  get billingCardLast4() {
    return this.profile?.stripe?.paymentMethod?.card?.last4;
  }
  get billingCardBrand() {
    return this.profile?.stripe?.paymentMethod?.card?.brand;
  }

  get subscribed() {
    return Boolean(this.profile?.subscriptionActive);
  }
}
