import { Stack } from "@mui/system";
import { observer } from "mobx-react-lite";

export default observer(function Page({ children, ...props }) {
  return (
    <Stack
      alignItems="center"
      {...props}
      sx={{
        height: "100%",
        width: "100%",
        bgcolor: "surface.600",
        ...props?.sx,
      }}
    >
      {children}
    </Stack>
  );
});
