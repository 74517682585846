import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";

import { useStore } from "../../mobx";

export default observer(function CreateTeam() {
  const { team } = useStore();

  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography>Company</Typography>
          <TextField
            value={team.name}
            onChange={({ target: { value } }) => team.set.name(value)}
            sx={{ width: "100%" }}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography>Location</Typography>
          <TextField
            value={team.location}
            onChange={({ target: { value } }) => team.set.location(value)}
            sx={{ width: "100%" }}
          />
        </Stack>
      </Stack>
    </>
  );
});
