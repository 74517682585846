import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Dialog } from "@mui/material";

import { store } from "../../mobx";

import { setSubscribeDialog } from "./setSubscribeDialog";
import { setUnsubscribeDialog } from "./setUnsubscribeDialog";
import { setSubscriptionCongratulationsDialog } from "./setSubscriptionCongratsDialog";

const setters = {
  setSubscribeDialog,
  setUnsubscribeDialog,
  setSubscriptionCongratulationsDialog,
};

export default observer(function DialogComponent() {
  const { dialog } = store;

  const {
    fullScreen,
    dialogProps,
    dialogTitleProps,
    dialogContentProps,
    dialogActionsProps,
    Title,
    Content,
    Actions,
    onClose,
    onTransitionExited,
  } = dialog;

  useEffect(() => {
    if (!dialog.open) {
      dialog.set.dialog();
    }
  }, [dialog, dialog.open]);

  // hack to deal with bad mobx dialog pattern, anytime a dialog setter's reference is changed
  // this will force the dialog to rerender
  useForceRerender();

  return (
    <Dialog
      keepMounted
      fullScreen={fullScreen}
      fullWidth
      open={dialog.open}
      onClose={() => {
        dialog.toggle();
        onClose();
      }}
      onTransitionExited={onTransitionExited}
      {...dialogProps}
    >
      {Title && <Title {...dialogTitleProps} />}

      {Content && <Content {...dialogContentProps} />}

      {Actions && <Actions {...dialogActionsProps} />}
    </Dialog>
  );
});

function useForceRerender() {
  const { dialog } = store;

  const depsTracker = useRef(setters);

  useEffect(() => {
    const depsAsEntries = Object.entries(setters);

    for (const [key, setter] of depsAsEntries) {
      const oldSetter = depsTracker.current[key];
      if (setter !== oldSetter) {
        setter();
        break;
      }
    }

    for (const [key, setter] of depsAsEntries) {
      depsTracker.current[key] = setter;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, setters]);
}
