import {
  db,
  ogCollection,
  query,
  where,
  onSnapshot,
  firestore,
  documentId,
} from "../services/firebase";
import { makeAutoObservable, reaction, transaction } from "mobx";
import { fetchFromApiServer } from "../services/api";

const params = new URLSearchParams(window.location.search);

const teamId = params.get("teamId");

//
export default class Team {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);

    reaction(
      () => {
        return {
          profile: this._.user.profile,
          loaded: this._.user.loaded,
        };
      },
      ({ profile, loaded }) => {
        if (!loaded) {
          this.reset();
          return;
        }

        // unsub previous listeners
        for (const dependency of this.dependencies) {
          dependency();
        }

        // team store is loaded in this case, short circuit
        if (profile.teams?.length === 0 || profile.teams === undefined) {
          this.set.loaded(true);
          return;
        }

        const q = query(
          ogCollection(db, "teams"),
          where(documentId(), "in", profile.teams)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          // TODO multiple team membership should be accounted for at some points,
          // we'll just keep a teams array and have a setter that's based off of all of the stored teams
          querySnapshot.forEach((doc) => {
            const team = doc.data();

            transaction(() => {
              this.set.name(team.name);
              this.set.location(team.location);
              this.set.members(team.members);
              this.set.owners(team.owners);
              this.set.loading(false);
              this.set.id(doc.id);
            });
          });
          this.set.loaded(true);
        });

        this.set.dependencies([...this.dependencies, unsubscribe]);
      }
    );
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }
  }

  set = {
    id: (id) => {
      this.id = id;
    },
    loading: (loading = true) => {
      this.loading = loading;
    },
    members: (members = []) => {
      this.members = members;
    },
    owners: (owners = []) => {
      this.owners = owners;
    },
    name: (name = teamId) => {
      this.name = name;
    },
    location: (location = "") => {
      this.location = location;
    },
    //
    dependencies: (dependencies = []) => {
      this.dependencies = dependencies;
    },
    loaded: (loaded = false) => {
      this.loaded = loaded;
    },
  };

  // TODO needs to be protected by backend
  removeMember = async (index, { updateDb = false } = {}) => {
    const member = this.members[index];

    this.set.members([
      ...this.members.slice(0, index),
      ...this.members.slice(index + 1),
    ]);

    if (updateDb) {
      await firestore.doc.update({
        path: `teams/${this.id}`,
        data: {
          members: this.members,
        },
      });
    }

    if (member.onboarded) {
      firestore.doc.removeItemFromArray({
        path: `users/${member.uid}`,
        field: "teams",
        value: this.id,
      });
    }
  };
  addMember = async (
    member = {
      name: undefined,
      email: undefined,
      phoneNumber: undefined,
      notifications: {
        text: true,
        app: false,
      },
    },
    { updateDb = false } = {}
  ) => {
    this.members = [...this.members, member];

    if (updateDb) {
      await firestore.doc.update({
        path: `teams/${this.id}`,
        data: {
          members: this.members,
        },
      });

      await this.sendTeamInvites();
    }
  };

  editMember = async (index, updatedMember, { updateDb = false } = {}) => {
    const newMembers = [...this.members];

    newMembers[index] = updatedMember;

    this.members = newMembers;

    if (updateDb) {
      await firestore.doc.update({
        path: `teams/${this.id}`,
        data: {
          members: this.members,
        },
      });
    }
  };

  sendTeamInvites = async () => {
    // add team member invite call here
    const response = await fetchFromApiServer(
      "https://onboardusers-5gnvjtys6a-uc.a.run.app",
      {
        method: "POST",
        body: JSON.stringify({
          teamId: this._.team.id,
        }),
      }
    );

    if (!response.ok) {
      console.error("Failed to send notifications to team members");
    }
  };

  createTeam = async () => {
    await firestore.collection.add({
      path: `teams`,
      data: {
        name: this.name,
        owners: [this._.user.session.uid],
        members: this.members,
        location: this.location,
      },
    });

    await this.sendTeamInvites();
  };

  updateTeamProp = async ({ prop, value }) => {
    this.set[prop](value);

    await firestore.doc.update({
      path: `teams/${this.id}`,
      data: {
        [prop]: value,
      },
    });

    this._.snackbar.set.message(
      <>
        Team {prop} changed to <b>{value}</b>
      </>
    );
  };

  get isOwner() {
    return this.owners.includes(this._.user.session.uid);
  }

  isValidPhoneNumber = (text = "") =>
    text.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
}
