import { makeAutoObservable } from "mobx";
//
export default class MediaBreakpoint {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }
  }

  set = {
    isPhone: (isPhone = undefined) => {
      this.isPhone = isPhone;
    },
  };
}
