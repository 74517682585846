import { Add, Remove } from "@mui/icons-material";
import {
  ListItem,
  TextField,
  List,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useState } from "react";

import { useStore } from "../../mobx";
import { stringToHsl } from "../../utilities";

export default observer(function RegisterUsers() {
  const [text, setText] = useState("");
  const { team } = useStore();

  const addMember = () => {
    team.addMember({ name: text });
    setText("");
  };

  return (
    <>
      <Stack spacing={2}>
        <List>
          {team.members.map((member, index) => {
            const { name } = member;

            return (
              <ListItem key={`${name}-${index}`} sx={{ color: "white" }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: stringToHsl(name) }}>{name[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} />
                <ListItemSecondaryAction>
                  <IconButton
                    size="small"
                    onClick={() => {
                      team.removeMember(index);
                    }}
                  >
                    <Remove />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>

        <Stack direction="row" width="100%" sx={{ pr: 2 }}>
          <TextField
            value={text}
            onChange={({ target: { value } }) => setText(value)}
            sx={{ width: "100%" }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                // Add your desired functionality here when the Enter key is pressed
                addMember();
              }
            }}
          />
          <Box sx={{ pt: "11px" }}>
            <IconButton size="small" onClick={addMember}>
              <Add />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
    </>
  );
});
