import { makeAutoObservable } from "mobx";

//
export default class GlobalFx {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }
  }

  set = {
    snowing: (snowing = false) => {
      this.snowing = snowing;
    },
  };
}
