import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Stack } from "@mui/system";

import { store } from "../../mobx";

export const SubsectionStack = observer(function SubsectionStack({
  title,
  titleProps,
  children,
  spacing = 8,
}) {
  const { isPhone } = store.mediaBreakpoint;

  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="center"
      spacing={spacing}
    >
      <Typography
        textAlign="center"
        {...titleProps}
        sx={{
          fontFamily: "PermanentMarker",
          fontSize: 36,
          lineHeight: "30px",
          color: "black",
          ...titleProps?.sx,
        }}
      >
        {title}
      </Typography>
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="center"
        spacing={isPhone ? 4 : 6}
        sx={{ px: "20%" }}
      >
        {children}
      </Stack>
    </Stack>
  );
});
