import { makeAutoObservable } from "mobx";
import { fetchFromApiServer } from "../services/api";

//
export default class Reporting {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }
  }

  set = {
    report: ({ selection = "", description = "", details = "" } = {}) => {
      this.report = { selection, description, details };
    },
    loading: (loading = true) => {
      this.loading = loading;
    },
  };

  sendReport = async () => {
    console.log("Sending report");
    const message = `Report from customer:
Selection: ${this.report.selection}

Description: ${this.report.description}

Details: ${this.report.details}
    `.trim();

    const response = await fetchFromApiServer(
      "https://sendsms-5gnvjtys6a-uc.a.run.app",
      {
        method: "POST",
        body: JSON.stringify({
          teamId: this._.team.id,
          message,
        }),
      }
    );

    if (!response.ok) {
      console.error("Failed to send report", response.status);
    }
  };
}
