import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Avatar,
  IconButton,
  Grid,
  CardActionArea,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  ArticleOutlined,
  Money,
  QrCode,
  Settings,
  People,
} from "@mui/icons-material";
import { stringToHsl } from "../../utilities";
import SingleCardPage from "../../components/Page/SingleCardPage";
import { store } from "../../mobx";

export default observer(function Home() {
  const items = [
    {
      name: "Reports",
      path: "/reportDashboard",
      Icon: ArticleOutlined,
    },
    {
      name: "QR Code",
      path: "/qr",
      Icon: QrCode,
    },

    {
      name: "Account",
      path: "/account",
      Icon: Settings,
    },
    {
      name: "Subscribe",
      path: "/account",
      Icon: Money,
    },
    {
      name: "Team",
      path: "/team",
      Icon: People,
    },
  ];

  const navigate = useNavigate();

  return (
    <SingleCardPage
      cardProps={{
        sx: {
          pt: 1,
        },
      }}
      cardHeaderProps={
        {
          // title: "Home",
        }
      }
      cardContentChildren={
        <Grid
          container
          justifyContent="space-between"
          rowSpacing={2}
          columnSpacing={2}
          columns={2}
        >
          {items.map(({ name, path, Icon }, index) => (
            <Grid
              key={name + index}
              item
              xs={index === items.length - 1 ? 2 : 1}
            >
              <CardActionArea
                onClick={() => {
                  navigate(path);
                }}
                sx={(theme) => ({
                  p: 4,
                  borderRadius: 4,
                  //   border: `1px solid ${stringToHsl(name)}`,
                  border: `1px solid ${theme.palette.grey[700]}`,
                  height: store.mediaBreakpoint.isPhone ? undefined : 200,
                  //   bgcolor: "red",
                })}
              >
                <Stack
                  alignItems="center"
                  justifyContent="space-around"
                  sx={{
                    borderRadius: 4,
                  }}
                  spacing={2}
                >
                  <Avatar sx={{ bgcolor: stringToHsl(name) }}>
                    <IconButton component="div">
                      <Icon />
                    </IconButton>
                  </Avatar>
                  <Typography>{name}</Typography>
                </Stack>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
});
