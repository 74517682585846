import { makeAutoObservable } from "mobx";

//
export default class Dialog {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }
  }

  set = {
    open: (open = false) => {
      this.open = open;
    },
    dialog: (
      configOptions = {
        fullScreen: false,
        dialogProps: undefined,
        dialogTitleProps: undefined,
        dialogContentProps: undefined,
        dialogActionsProps: undefined,
        Title: undefined,
        Content: undefined,
        Actions: undefined,
        onClose: () => undefined,
        onTransitionExited: () => undefined,
      }
    ) => {
      for (const [key, value] of Object.entries(configOptions)) {
        this[key] = value;
      }
    },
  };

  toggle = () => {
    this.set.open(!this.open);
  };
}
