import { Button, CircularProgress, Fade, MobileStepper } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import SingleCardPage from "../../components/Page/SingleCardPage";

import OnboardingWelcome from "./1_welcome";
import OnboardingSignIn from "./2_signin";
import OnboardingConfirmDetails from "./3_confirmDetails";
import OnboardingSetNotifications from "./4_setNotifications";
import OnboardingCongratulations from "./5_congratulations";

import { useStore } from "../../mobx";
import { firestore } from "../../services/firebase";

export default observer(function Signup() {
  const navigate = useNavigate();

  const { user, snackbar } = useStore();

  const [stepIndex, setStepIndex] = useState(0);

  const [state, setState] = useState("");

  const inviteId = new URL(window.location.href).searchParams.get("inviteId");

  useEffect(() => {
    if (inviteId) {
      firestore.doc.get({ path: `invites/${inviteId}` }).then(async (doc) => {
        const data = doc.data();

        if (data) {
          const { email, name, phoneNumber, teamId } = data;

          const teamDoc = await firestore.doc.get({ path: `teams/${teamId}` });

          const teamData = teamDoc.data();

          const { name: teamName, location } = teamData;

          setState({
            teamId,
            teamName,
            location,
            email,
            name,
            phoneNumber,
            teamData,
          });

          return;
        }

        snackbar.set.message("This invite has expired.");

        navigate("/home");
      });
    }
  }, [navigate, snackbar, inviteId]);

  const steps = [
    {
      title: "Welcome",
      subheader: "Let's get started",
      Component: <OnboardingWelcome state={state} setState={setState} />,
    },
    {
      title: "Sign In",
      Component: (
        <OnboardingSignIn
          state={state}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
        />
      ),

      prevButtonProps: {
        hide: true,
      },
      nextButtonProps: {
        hide: true,
      },
    },
    {
      title: "Confirm Details",
      subheader: "Everything look good?",
      Component: <OnboardingConfirmDetails state={state} setState={setState} />,
    },
    {
      title: "Set Notification Preferences",
      subheader: "When do you work?",
      Component: (
        <OnboardingSetNotifications
          state={state}
          onChange={(availability) => {
            setState((state) => ({
              ...state,
              availability,
            }));
          }}
        />
      ),
      // TODO this all needs to validate against the user's email on the backend
      actionHandler: async () => {
        let foundMember = false;

        for (const member of state.teamData.members) {
          if (member.email === state.email) {
            member.onboarded = true;
            member.availability = state.availability;
            member.uid = user.session.uid;
          }
        }

        if (!foundMember) {
          console.error("Could not find member when onboarding");
        }

        await user.setProfile({
          teams: [state.teamId],
        });

        await firestore.doc.update({
          path: `teams/${state.teamId}`,
          data: state.teamData,
        });

        await firestore.doc.delete({
          path: `invites/${inviteId}`,
        });
      },
    },
    {
      title: "Congratulations! 🎉",
      subheader: "You're all setup",
      titleTypographyProps: {
        textAlign: "center",
      },
      subheaderTypographyProps: {
        textAlign: "center",
      },
      Component: <OnboardingCongratulations />,
      prevButtonProps: {
        hide: true,
      },
      nextButtonProps: {
        text: "Finish",
      },
      actionHandler: async () => {
        navigate("/home");
      },
    },
  ];

  const {
    title,
    titleTypographyProps,
    subheaderTypographyProps,
    subheader,
    Component,
    actionHandler = async () => undefined,
    prevButtonProps,
    nextButtonProps,
  } = steps[stepIndex];

  return (
    <>
      <SingleCardPage
        cardProps={{
          //   sx: { mt: 0, width: "calc(100% - 32px)" },
          sxDesktop:
            stepIndex === 3
              ? {
                  mt: 0,
                  width: "100vw",
                  height: "calc(100vw - 56px)",
                  borderRadius: 0,
                }
              : undefined,
        }}
        cardHeaderProps={{
          title,
          titleTypographyProps,
          subheader,
          subheaderTypographyProps,
        }}
        cardContentProps={{}}
        cardContentChildren={
          <>
            {state.teamId ? (
              <Fade in={true}>
                <Box>{Component}</Box>
              </Fade>
            ) : (
              <Stack width="100%">
                <CircularProgress sx={{ margin: "auto" }} />
              </Stack>
            )}
          </>
        }
        Action={
          <Stack alignItems="center" sx={{ pl: 1, width: "100%" }}>
            <Stack direction="row" spacing={2}>
              {!prevButtonProps?.hide && (
                <Button
                  variant="contained"
                  // fullWidth
                  disabled={stepIndex === 0}
                  onClick={() => {
                    setStepIndex(stepIndex - 1);
                  }}
                >
                  Prev
                </Button>
              )}

              {!nextButtonProps?.hide && (
                <Button
                  variant="contained"
                  // fullWidth
                  onClick={async () => {
                    await actionHandler();
                    setStepIndex(stepIndex + 1);
                  }}
                >
                  {nextButtonProps?.text || "Next"}
                </Button>
              )}
            </Stack>
            <MobileStepper
              activeStep={stepIndex}
              steps={steps.length}
              sx={{ position: "relative", bgcolor: "transparent", mt: 1 }}
            />
          </Stack>
        }
      />
    </>
  );
});
