import { FormControl } from "@mui/base";

import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";

import { useStore } from "../../mobx";

export default observer(function SendReport() {
  const { reporting } = useStore();

  return (
    <>
      <Typography paragraph color="text.secondary">
        Please provide as much detail as possible
      </Typography>

      <Typography paragraph color="text.secondary" sx={{ mb: 3 }}>
        After submitting your report, you will be connected with a member of the
        establishment in the next screen
      </Typography>

      <Stack spacing={3}>
        <FormControl>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ color: "text.primary" }}
          >
            Select One
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={({ target: { value } }) =>
              reporting.set.report({
                ...reporting.report,
                selection: value,
              })
            } // Attaching handleRadioChange here
          >
            <FormControlLabel
              value="escort"
              control={<Radio />}
              label="Escort me to my car"
            />
            <FormControlLabel
              value="uber"
              control={<Radio />}
              label="Call me an uber"
            />
            <FormControlLabel
              value="police"
              control={<Radio />}
              label="Activate law enforcement"
            />
          </RadioGroup>
        </FormControl>
        <Stack spacing={1}>
          <Typography>Person Description</Typography>
          <TextField
            multiline
            value={reporting.report.description}
            onChange={({ target: { value } }) =>
              reporting.set.report({
                ...reporting.report,
                description: value,
              })
            }
            sx={{ width: "100%" }}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography>Addtional details</Typography>
          <TextField
            multiline
            value={reporting.report.details}
            onChange={({ target: { value } }) =>
              reporting.set.report({
                ...reporting.report,
                details: value,
              })
            }
            sx={{ width: "100%" }}
          />
        </Stack>
      </Stack>
    </>
  );
});
