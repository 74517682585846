import { store } from "../../mobx";
import {
  Button,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";

function setSubscriptionCongratulationsDialog() {
  store.dialog.set.dialog({
    Title: () => <DialogTitle>Success</DialogTitle>,
    Content: observer(() => {
      return (
        <DialogContent>
          <Typography>Thank you for subscribing!</Typography>
          <CardMedia
            component="img"
            src="/baby-child.gif"
            sx={{ borderRadius: 2, my: 1 }}
          />
          <Typography>
            Your next billing cycle will occur on{" "}
            {store.user.subscriptionEndString}
          </Typography>
        </DialogContent>
      );
    }),
    Actions: () => (
      <DialogActions>
        <Button
          onClick={() => {
            store.dialog.set.open(false);
            store.globalFx.set.snowing(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    ),
    onClose: () => {
      console.log("Setting subscribtion to false");
      store.globalFx.set.snowing(false);
    },
  });
  console.log("FIRING");
  store.dialog.set.open(true);
  store.globalFx.set.snowing(true);
}

export { setSubscriptionCongratulationsDialog };
