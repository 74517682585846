import { makeAutoObservable } from "mobx";

//
export default class Drawer {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }
  }

  set = {
    open: (open = false) => {
      this.open = open;
    },
  };

  toggle = () => {
    this.open = !this.open;
  };
}
