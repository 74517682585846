import { Box, CardMedia, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Stack } from "@mui/system";

import { store } from "../../mobx";
import { ArrowRight } from "@mui/icons-material";

import { SubsectionStack } from "./SubsectionStack";

export const FeaturesCarousel = observer(function FeaturesCarousel({
  title = "How can watchdog help your business?",
  spacing = 8,
}) {
  const { isPhone } = store.mediaBreakpoint;

  const items = [
    {
      src: "/qrSample.png",
      header: "QR Tech",
      text: "Use QR technology to lower the barrier between your business and its patrons. Never will fear get in the way of a customer reporting a problem!",
      animate: true,
    },
    {
      icon: true,
    },
    {
      src: "text-message-sample.jpg",
      header: "Alerts",
      text: "Alerts are automatically sent directly to your staff, allowing for truly immediate action.",
      animate: true,
    },
    {
      icon: true,
    },
    {
      src: "/report-sample.png",
      header: "Reports",
      text: "Develop a never before seen trust with your patrons, ensuring a customer that feels safe and returns.",
      animate: true,
    },
  ];

  return (
    <SubsectionStack title={title} spacing={spacing}>
      <Stack
        width="100%"
        height={"fit-content"}
        direction={isPhone ? "column" : "row"}
        alignItems={isPhone ? "center" : "flex-start"}
        justifyContent="center"
        spacing={isPhone ? 5 : 12}
      >
        {items.map(({ icon, src, header, text, animate }, index) => {
          if (icon) {
            return isPhone ? null : (
              <Box
                key={index}
                display="flex"
                alignSelf="center"
                sx={{ height: "100%", pb: 18.5 }}
              >
                <ArrowRight
                  sx={{
                    height: 40,
                    width: 40,
                    color: "#fff2",
                  }}
                />
              </Box>
            );
          }

          const percentDifference = `${index * 2 + 2}`;
          return (
            <Stack
              key={index}
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{
                width: 272,
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontFamily: "PermanentMarker",
                  fontSize: 22,
                  lineHeight: "18px",
                  color: "black",
                }}
              >
                {header}
              </Typography>
              <Stack
                alignItems="center"
                sx={{
                  bgcolor: "black",
                  width: 272,
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "black",
                    width: "fit-content",
                    borderRadius: 4,
                  }}
                >
                  <CardMedia
                    component="img"
                    src={src}
                    sx={{
                      height: 192,
                      borderRadius: 4,
                      bgcolor: "black",
                      objectFit: "contain",
                      ...(animate
                        ? {
                            animation: `rotate${percentDifference} 10s linear`,
                            [`@keyframes rotate${percentDifference}`]: {
                              "0%": {
                                transform:
                                  "rotateX(8deg) rotateY(0deg) scale(1)",
                              },
                              [`${percentDifference}%`]: {
                                transform:
                                  "rotateX(8deg) rotateY(90deg) scale(1)",
                              },
                              "50%": {
                                transform:
                                  "rotateX(0deg) rotateY(0deg) scale(1)",
                              },
                              "75%": {
                                transform:
                                  "rotateX(0deg) rotateY(0deg) scale(1)",
                              },

                              "100%": {
                                transform:
                                  "rotateX(0deg) rotateY(0deg) scale(1)",
                              },
                            },
                          }
                        : {}),
                    }}
                  />
                </Box>
              </Stack>

              <Typography
                textAlign="center"
                sx={{
                  fontSize: 20,
                  color: "black",
                }}
              >
                {text}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </SubsectionStack>
  );
});
