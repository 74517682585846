import { IconButton, Toolbar, AppBar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";

// export default observer(function Signup() {
import { Logout, Menu as MenuIcon } from "@mui/icons-material";

import { store } from "../mobx";
import { useNavigate } from "react-router";

export default observer(function AppBarStuff() {
  const navigate = useNavigate();
  const { appbar, user, drawer, mediaBreakpoint } = store;

  return appbar.hidden ? null : (
    <AppBar elevation={0} sx={{ height: 56, justifyContent: "center" }}>
      <Toolbar sx={{ justifyContent: "space-between" }} variant="dense">
        <IconButton
          edge="start"
          onClick={() => drawer.set.open(true)}
          color="inherit"
          aria-label="menu"
          size="large"
        >
          <MenuIcon />
        </IconButton>

        <Box
          sx={{
            // width: 92,
            height: 48,
            p: 1,
            mb: 1,
            overflow: "hidden",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
          }}
          onClick={() => navigate("/home")}
        >
          {mediaBreakpoint.isPhone ? (
            <Typography
              textAlign="center"
              sx={(theme) => ({
                fontFamily: "PermanentMarker",
                fontSize: 28,
                lineHeight: "36px",
                WebkitTextStroke: 1,
                WebkitTextStrokeColor: theme.palette.primary.main,
                WebkitTapHighlightColor: "transparent",
                color: "black",
              })}
            >
              Watchdog
            </Typography>
          ) : (
            <Typography
              // variant="h4"
              textAlign="center"
              sx={(theme) => ({
                fontFamily: "PermanentMarker",
                fontSize: 28,
                lineHeight: "36px",
                WebkitTextStroke: 1,
                WebkitTextStrokeColor: theme.palette.primary.main,
                color: "black",
              })}
            >
              Watchdog
            </Typography>
          )}

          {/* <CardMedia
            component="img"
            src="/main-logo-4.png"
            sx={{
              // pt: 0.5,

              transform: "translate(12%, -17%) scale(1)",

              // width: 1000,

              // objectFit: "contain",

              //
            }}
            onClick={() => navigate("/home")}
          /> */}
        </Box>

        {/* <Typography
            variant="h4"
            sx={{
              backgroundImage: "linear-gradient(to right, orange,  violet)",
              "-webkit-background-clip": "text",
              color: "transparent",
              animation: "move 3000s linear infinite",
              // "background-size": "400% 100%",
              //   position: "absolute",

              left: "calc(50vw - 43.835px)",
              "@keyframes move": {
                to: {
                  "background-position": "4500vh",
                },
              },
              cursor: "pointer",
            }}
            
          >
            WatchDog™
          </Typography> */}

        <IconButton
          edge="start"
          onClick={async () => {
            await user.signOut();

            navigate("/");
          }}
          color="inherit"
          aria-label="menu"
          size="large"
          sx={{ visibility: "hidden" }}
        >
          <Logout />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
});
