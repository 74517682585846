import { CardMedia } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";

export default observer(function SetNotificationStyle() {
  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <>
      <Stack spacing={2}>
        <CardMedia
          component="img"
          src="https://media.tenor.com/iNpeACeWU2UAAAAC/baby-child.gif"
          sx={{ borderRadius: 2 }}
        />
      </Stack>
    </>
  );
});
