import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/system";

import { store } from "../../mobx";

const PINK = "#FF008A";

export const NavRail = observer(function NavRail() {
  const navigate = useNavigate();

  const items = [
    // {
    //   text: "Services",
    // },
    // {
    //   text: "About",
    // },
    // {
    //   text: "Contact",
    // },

    {
      text: "Login",
      onClick: async () => {
        await store.user.authWithGoogle();
        navigate("/home");
      },
    },
  ];

  const { isPhone } = store.mediaBreakpoint;

  return (
    <Stack
      direction="row"
      sx={{
        position: "absolute",
        width: "100vw",
        height: 56,
        px: isPhone ? 1 : 6,
        bgcolor: "#0007",
        zIndex: 1,
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent={"flex-end"}
        spacing={isPhone ? 1 : 6}
        sx={(theme) => ({
          px: store.mediaBreakpoint.isPhone ? 1 : 2,
          "& h5": {
            fontSize: isPhone ? 21 : undefined,
            WebkitTextStroke: isPhone ? 1 : 2,
            WebkitTextStrokeColor: "black",
            // color: theme.palette.secondary.main,
            color: PINK,

            fontFamily: "PermanentMarker",
            cursor: "pointer",
          },
        })}
      >
        {items.map(({ text, onClick }, index) => (
          <Box key={index} sx={{ WebkitTapHighlightColor: "transparent" }}>
            <Typography variant="h5" onClick={onClick}>
              {text}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
});
