import { Add } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import { observer } from "mobx-react-lite";

import { TeamMemberListItemTeamOwner } from "./EditTeamMembersList";

import { store } from "../../mobx";
import { useState } from "react";

function AddTeamMembersList() {
  const { team, dialog } = store;

  return (
    <Box>
      <List sx={{ pt: 0 }}>
        <ListItem sx={{ pt: 0, px: 0 }}>
          <ListItemText sx={{ mt: 0 }} primary="Members" />

          <ListItemSecondaryAction sx={{ top: `calc(50% + -4px)` }}>
            <IconButton
              onClick={() => {
                dialog.set.dialog({
                  Title: () => <DialogTitle>Add Team Member</DialogTitle>,
                  Content: () => {
                    const [nameLocal, setNameLocal] = useState("");
                    const [emailLocal, setEmailLocal] = useState("");
                    const [phoneNumberLocal, setPhoneNumberLocal] =
                      useState("");

                    const [
                      { textLocal, appLocal },
                      setNotificationPreferences,
                    ] = useState({
                      textLocal: true,
                      appLocal: false,
                    });

                    const error = Boolean(
                      phoneNumberLocal &&
                        !team.isValidPhoneNumber(phoneNumberLocal)
                    );

                    return (
                      <>
                        <DialogContent>
                          <Stack spacing={4}>
                            <Stack spacing={1}>
                              <Typography>Name</Typography>
                              <TextField
                                value={nameLocal}
                                onChange={({ target: { value } }) => {
                                  setNameLocal(value);
                                }}
                                sx={{ width: "100%" }}
                              />
                            </Stack>
                            <Stack spacing={1}>
                              <Typography>Email</Typography>
                              <TextField
                                value={emailLocal}
                                onChange={({ target: { value } }) => {
                                  setEmailLocal(value);
                                }}
                                sx={{ width: "100%" }}
                              />
                            </Stack>
                            <Stack spacing={1}>
                              <Typography>Phone Number</Typography>
                              <TextField
                                error={error}
                                helperText={
                                  error
                                    ? "Must be a valid phone number, e.g. XXX-XXX-XXXX"
                                    : "Input phone number e.g. XXX-XXX-XXXX"
                                }
                                value={phoneNumberLocal}
                                onChange={({ target: { value } }) =>
                                  setPhoneNumberLocal(value)
                                }
                                sx={{ width: "100%" }}
                              />
                            </Stack>

                            <Stack spacing={1}>
                              <Typography>Notifications</Typography>

                              <Table>
                                <TableHead>
                                  <TableCell>Text</TableCell>
                                  <TableCell>App</TableCell>
                                </TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Checkbox
                                      checked={textLocal}
                                      onChange={({ target: { checked } }) =>
                                        setNotificationPreferences((state) => ({
                                          ...state,
                                          textLocal: checked,
                                        }))
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox
                                      checked={appLocal}
                                      onChange={({ target: { checked } }) =>
                                        setNotificationPreferences((state) => ({
                                          ...state,
                                          appLocal: checked,
                                        }))
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </Table>
                            </Stack>
                          </Stack>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              team.addMember(
                                {
                                  name: nameLocal,
                                  email: emailLocal,
                                  phoneNumber: phoneNumberLocal,
                                  notifications: {
                                    text: true,
                                    app: false,
                                  },
                                },
                                {
                                  updateDb: true,
                                }
                              );
                              dialog.toggle();
                            }}
                          >
                            Save
                          </Button>
                        </DialogActions>
                      </>
                    );
                  },
                });
                dialog.toggle();
              }}
            >
              <Add />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <List>
        {team.members.map((member, index) => {
          return (
            <TeamMemberListItemTeamOwner
              key={`${member.name}-${index}`}
              index={index}
              member={member}
            />
          );
        })}
      </List>
    </Box>
  );
}

export default observer(AddTeamMembersList);
