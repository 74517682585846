import { Print } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { observer } from "mobx-react-lite";

import SingleCardPage from "../../components/Page/SingleCardPage";

import QR from "../../components/QR";
import { printImage } from "../../utilities";

export default observer(function Signup() {
  return (
    <SingleCardPage
      containerProps={
        {
          // justifyContent: "center",
          // border: "1px solid red",
        }
      }
      cardProps={
        {
          // elevation: 0,
          // sx: {
          //   width: "95%",
          //   pb: 16,
          //   height: "fit-content",
          // },
        }
      }
      cardHeaderProps={{
        title: "QR Code",

        action: (
          <IconButton onClick={printImage}>
            <Print />
          </IconButton>
        ),
      }}
      cardContentProps={{}}
      cardContentChildren={
        <Stack width="100%" height="100%" justifyContent="center">
          <QR />
        </Stack>
      }
    />
  );
});
