import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
} from "@mui/material";

import { observer } from "mobx-react-lite";

import { useStore } from "../../mobx";
import { stringToHsl } from "../../utilities";

export default observer(function SetNotificationStyle() {
  const { team } = useStore();

  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <>
      <Typography color="text.secondary" paragraph>
        Specify where reports are sent.
      </Typography>
      <Typography color="text.secondary" paragraph>
        If "App" is selected, your team member will be sent an email that will
        guide them through an installation of the notification service on their
        phone!
      </Typography>

      <Table
        sx={{
          width: "100%",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Member</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>App</TableCell>
          </TableRow>
        </TableHead>
        {team.members.map((member, index) => {
          return (
            <TeamMemberListItem
              key={`${member.name}-${index}`}
              index={index}
              member={member}
            />
          );
        })}
      </Table>
    </>
  );
});

const TeamMemberListItem = observer(function TeamMemberListItem({
  index,
  member: {
    name,
    email,
    phoneNumber,
    notifications: { text = true, app = false } = {},
  },
  member,
}) {
  const { team } = useStore();

  return (
    <TableRow>
      <TableCell>
        <ListItemAvatar sx={{ alignSelf: "flex-start", pt: 1 }}>
          <Avatar sx={{ bgcolor: stringToHsl(name) }}>{name[0]}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={
            <>
              <Typography component="span" sx={{ display: "block" }}>
                {phoneNumber || "Phone Number: none"}
              </Typography>
              <Typography component="span" sx={{ display: "block" }}>
                {email || "Email: none"}
              </Typography>
            </>
          }
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={text}
          onChange={({ target: { checked } }) =>
            team.editMember(index, {
              ...member,
              notifications: {
                ...member.notifications,
                text: checked,
              },
            })
          }
        />
      </TableCell>
      <TableCell>
        <Checkbox
          onChange={({ target: { checked } }) =>
            team.editMember(index, {
              ...member,
              notifications: {
                ...member.notifications,
                app: checked,
              },
            })
          }
        />
      </TableCell>
    </TableRow>
  );
});
