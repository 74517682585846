import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  createTheme,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import { StoreProvider } from "./mobx";
import { BrowserRouter } from "react-router-dom";
import { fetchFromApiServer } from "./services/api";

if (process.env.NODE_ENV !== "production") {
  // const testUrl = "http://localhost:5001/watchdog-6de84/us-central1/test";
  const testUrl = "https://test-5gnvjtys6a-uc.a.run.app";

  fetchFromApiServer(testUrl, {
    method: "post",
  }).catch(console.error);
}

const colorCount = 6;

const surfaceShades = Object.fromEntries(
  Array(colorCount)
    .fill(null)
    .map((v, index) => {
      const key = index === 0 ? 50 : index * 100;
      const darkness = (index / colorCount) * 255;

      const color = 255 - darkness;

      return [key, `rgb(${color},${color},${color})`];
    })
);

const theme = createTheme({
  palette: {
    mode: "dark",
    surface: {
      main: orange[200],
      ...surfaceShades,
    },
    primary: {
      main: orange[500],
      ...orange,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            "@font-face": {
              fontFamily: "PermanentMarker",
              src: `url('PermanentMarker.ttf')`,
            },
            "*::-webkit-scrollbar": {
              width: "2px",
              height: "2px",
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.secondary.main,
              borderRadius: "8px",
            },
            "*::-webkit-scrollbar-thumb:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
            html: {
              scrollbarColor: `${theme.palette.secondary.main} transparent`,
              overflowX: "hidden",
            },
          }}
        />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
