import { store } from "../../mobx";
import { ContentCopy, Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import copy from "copy-to-clipboard";

import SingleCardPage from "../../components/Page/SingleCardPage";

import EditableField from "../../components/ListItem/EditableField";
import AddTeamMemberList from "../../components/Lists/AddTeamMemberList";
import { setSubscribeDialog } from "../../components/Dialog/setSubscribeDialog";
import { setUnsubscribeDialog } from "../../components/Dialog/setUnsubscribeDialog";
import { fetchFromApiServer } from "../../services/api";

import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
TimeAgo.addDefaultLocale(en);

export default observer(function Signup() {
  const { team, snackbar } = store;

  return (
    <>
      <SingleCardPage
        cardHeaderProps={{
          title: "Account Details",
        }}
        cardContentProps={{}}
        cardContentChildren={
          team.loading ? (
            <CircularProgress />
          ) : (
            <Stack spacing={4}>
              <Box>
                <Typography>Team</Typography>
                <Stack>
                  <EditableField
                    label="Name"
                    value={team.name}
                    onSave={(updatedValue) =>
                      team.updateTeamProp({
                        prop: "name",
                        value: updatedValue,
                      })
                    }
                  />
                  <EditableField
                    label="Location"
                    value={team.location}
                    onSave={(updatedValue) =>
                      team.updateTeamProp({
                        prop: "location",
                        value: updatedValue,
                      })
                    }
                  />
                </Stack>

                <Divider />
              </Box>
              <Box>
                <Typography>Subscription</Typography>
                <List>
                  <ListItem>
                    {store.user.subscribed === true ? (
                      <ListItemText>
                        <b>Watchdog Pro</b>
                      </ListItemText>
                    ) : store.user.subscribed === false ? (
                      <ListItemText>
                        <b>Watchdog Pro (Cancelled)</b>
                      </ListItemText>
                    ) : (
                      <ListItemText>
                        <b>Free trial</b>
                      </ListItemText>
                    )}

                    <ListItemSecondaryAction>
                      {store.user.subscribed ? (
                        <>
                          <Button
                            color="inherit"
                            variant="outlined"
                            onClick={setUnsubscribeDialog}
                          >
                            Unsubscribe
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={setSubscribeDialog}
                        >
                          Subscribe
                        </Button>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {store.user.subscribed ? (
                        `Next billing cycle: ${store.user.subscriptionEndString}`
                      ) : (
                        <span>
                          Expires:{" "}
                          <ReactTimeAgo date={store.user.subscriptionEnd} />
                        </span>
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Rate: $3 per month</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Card:{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        <b>{store.user.billingCardBrand}</b>
                      </span>{" "}
                      ending in {store.user.billingCardLast4}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>

                <Divider />
              </Box>

              <Box>
                <AddTeamMemberList />
                <Divider />
              </Box>

              <Box>
                <List>
                  <ListItem>
                    <ListItemText>id: {team.id}</ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          copy(team.id);
                          snackbar.set.message("Copied to clipboard");
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Box>
            </Stack>
          )
        }
        // Action={}
      />
    </>
  );
});
