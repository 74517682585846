import { Button, MobileStepper } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useState } from "react";

import SingleCardPage from "../../components/Page/SingleCardPage";
import SendReport from "./sendReport";
import AwaitContact from "./awaitContact";

import { useStore } from "../../mobx";
import { firestore } from "../../services/firebase";

export default observer(function Signup() {
  const { reporting } = useStore();
  const [stepIndex, setStepIndex] = useState(0);

  const teamId = new URL(window.location.href).searchParams.get("teamId");

  const steps = [
    {
      title: "Send Report",
      subheader: "Add details below",
      Component: <SendReport />,
      actionHandler: async () => {
        await firestore.collection.add({
          path: `reports`,
          data: {
            created: new Date(),
            teamId: teamId,
            report: reporting.report,
          },
        });

        await reporting.sendReport();
      },
    },
    {
      title: reporting.loading ? "Action in progress..." : "Success",
      subheader: reporting.loading
        ? "A team member will be assisting you shortly"
        : "Team member has arrived",
      Component: <AwaitContact />,
    },
  ];

  const { title, subheader, Component, actionHandler } = steps[stepIndex];

  return (
    <>
      <SingleCardPage
        cardHeaderProps={{
          title,
          subheader,
        }}
        cardContentProps={{}}
        cardContentChildren={<>{Component}</>}
        Action={
          <Stack alignItems="center" sx={{ pl: 1, width: "100%" }}>
            <Stack direction="row" spacing={2}>
              {!reporting.loading ? (
                <>
                  <Button
                    // variant="contained"
                    // fullWidth
                    disabled={stepIndex === 0}
                    onClick={() => {
                      setStepIndex(stepIndex - 1);
                      reporting.set.loading(true);
                    }}
                  >
                    Prev
                  </Button>
                  <Button variant="contained">Ok</Button>
                </>
              ) : stepIndex !== steps.length - 1 ? (
                <>
                  <Button
                    variant="contained"
                    // fullWidth
                    disabled={stepIndex === 0}
                    onClick={() => {
                      setStepIndex(stepIndex - 1);
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="contained"
                    // fullWidth
                    onClick={async () => {
                      await actionHandler();
                      if (stepIndex === 0) {
                        setTimeout(() => reporting.set.loading(false), 3e3);
                      }
                      setStepIndex(stepIndex + 1);
                    }}
                  >
                    Next
                  </Button>{" "}
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    // fullWidth
                    disabled={stepIndex === 0}
                    onClick={() => {
                      setStepIndex(stepIndex - 1);
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="contained"
                    // fullWidth
                    onClick={() => {
                      setStepIndex(stepIndex + 1);
                    }}
                  >
                    Finish
                  </Button>
                </>
              )}
            </Stack>
            <MobileStepper
              activeStep={stepIndex}
              steps={steps.length}
              sx={{ position: "relative", bgcolor: "transparent", mt: 1 }}
            />
          </Stack>
        }
      />
    </>
  );
});
