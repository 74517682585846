import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { store } from "../mobx";
import { useEffect } from "react";

export function useSetDevice() {
  const theme = useTheme();

  const isMobile = !!useMediaQuery(theme.breakpoints.down("sm"));

  // defer state update 1 render cycle to prevent react freaking out
  // it freaks out because there is a render being issued out of the expected order of the shadow DOM
  // without this, the observing components in the mobx store re-render before the root of the shadow DOM renders
  // with this, React reflows the base of the app because useMediaQuery fires, it then reflows the mobx observing components
  useEffect(() => {
    store.mediaBreakpoint.set.isPhone(isMobile);
  }, [isMobile]);
}
