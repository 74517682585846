import { makeAutoObservable } from "mobx";
//
export default class AppBar {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }
  }

  set = {
    hidden: (hidden = false) => {
      this.hidden = hidden;
    },
  };
}
