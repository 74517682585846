export const prettyPrint = (obj) => JSON.parse(JSON.stringify(obj));

const getBrightColor = (hash) => {
  return `hsl(${~~(360 * hash)},90%,70%)`;
};

const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const stringToHsl = (str) => {
  return getBrightColor(cyrb53(str));
};

export const printImage = function printImage() {
  var printWindow = window.open("", "_blank", "width=600,height=400");
  var imageToPrint = document.getElementById("qr-code-image").outerHTML;
  printWindow.document.write(
    "<html><head><title>Print Image</title></head><body>" +
      imageToPrint +
      "</body></html>"
  );
  printWindow.document.close();
  printWindow.print();
};
