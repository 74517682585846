import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../mobx";

export default observer(function OnboardingSignIn({
  state: { name, teamName, email },
  stepIndex,
  setStepIndex,
}) {
  const { user } = useStore();
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (!user.session.isAnonymous && buttonClicked) {
      setStepIndex(stepIndex + 1);
    }
  }, [setStepIndex, stepIndex, buttonClicked, user.session]);
  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <Stack spacing={4}>
      <Typography textAlign="center">
        For security purposes, please sign in with your <b>{email}</b> account
      </Typography>

      <Stack alignItems="center" sx={{ width: "100%", margin: "auto" }}>
        <Button
          variant="contained"
          sx={{ width: "fit-content" }}
          onClick={async () => {
            await user.authWithGoogle();
            setButtonClicked(true);
          }}
        >
          Sign In
        </Button>
      </Stack>
    </Stack>
  );
});
