import { Button, IconButton, MobileStepper } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import SingleCardPage from "../../components/Page/SingleCardPage";
import CreateTeam from "./1_createTeam";
import RegisterUsers from "./2_registerUsers";
import FinalizeTeamInfo from "./3_finalizeTeamInfo";
import SetNotificationStyle from "./4_setNotificationStyle";
import PrintQRCode from "./5_printQRCode";
import ConnectPayment from "./6_connectPayment";
import { useStore } from "../../mobx";
import { useNavigate } from "react-router";
import { printImage } from "../../utilities";
import { Print } from "@mui/icons-material";

export default observer(function Signup() {
  const { team } = useStore();
  const [stepIndex, setStepIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (team.id) {
      navigate("/home");
    }
  }, [navigate, team.id]);

  const steps = [
    {
      title: "Create Team",
      subheader: "Add details below",
      Component: <CreateTeam />,
    },
    {
      title: "Onboard Team Members",
      subheader: "Add team member names below",
      Component: <RegisterUsers />,
    },
    {
      title: "Finalize Team Info",
      subheader: "Add finishing touches",
      Component: <FinalizeTeamInfo />,
    },
    {
      title: "Set Notification Preferences",
      subheader: "Almost done!",
      Component: <SetNotificationStyle />,

      actionHandler: async () => {
        await team.createTeam();
      },
    },
    {
      title: "Print QR Code",
      subheader: "Final Step!",
      Component: <PrintQRCode />,

      action: (
        <IconButton onClick={printImage}>
          <Print />
        </IconButton>
      ),
      prevButtonProps: {
        hide: true,
      },
    },
    {
      title: "Congratulations! 🎉",
      titleTypographyProps: {
        textAlign: "center",
      },
      Component: <ConnectPayment />,
      prevButtonProps: {
        hide: true,
      },
      nextButtonProps: {
        text: "Finish",
      },
      actionHandler: async () => {
        navigate("/home");
      },
    },
  ];

  const {
    title,
    titleTypographyProps,
    subheader,
    Component,
    action,
    actionHandler = async () => undefined,
    prevButtonProps,
    nextButtonProps,
  } = steps[stepIndex];

  return (
    <>
      <SingleCardPage
        // this prevents a flash if a user in a team visits signup
        loading={team.id}
        cardProps={
          {
            //   sx: { mt: 0, width: "calc(100% - 32px)" },
          }
        }
        cardHeaderProps={{
          title,
          titleTypographyProps,
          subheader,
          action: action,
        }}
        cardContentProps={{}}
        cardContentChildren={<>{Component}</>}
        Action={
          <Stack alignItems="center" sx={{ pl: 1, width: "100%" }}>
            <Stack direction="row" spacing={2}>
              {!prevButtonProps?.hide && (
                <Button
                  variant="contained"
                  // fullWidth
                  disabled={stepIndex === 0}
                  onClick={() => {
                    setStepIndex(stepIndex - 1);
                  }}
                >
                  Prev
                </Button>
              )}
              <Button
                variant="contained"
                // fullWidth
                onClick={async () => {
                  await actionHandler();
                  setStepIndex(stepIndex + 1);
                }}
              >
                {nextButtonProps?.text || "Next"}
              </Button>{" "}
            </Stack>
            <MobileStepper
              activeStep={stepIndex}
              steps={steps.length}
              sx={{ position: "relative", bgcolor: "transparent", mt: 1 }}
            />
          </Stack>
        }
      />
    </>
  );
});
