import {
  Button,
  List,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useState } from "react";
import { TeamMemberListItem } from "../../components/Lists/EditTeamMembersList";

import { useStore } from "../../mobx";

export default observer(function OnboardingConfirmDetails({
  state: { name, phoneNumber, email },
  setState,
}) {
  const { team, dialog } = useStore();

  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <>
      <List>
        <TeamMemberListItem
          member={{ name, phoneNumber, email }}
          editAction={() => {
            dialog.set.dialog({
              Title: () => <DialogTitle>Edit</DialogTitle>,
              Content: () => {
                const [phoneNumberLocal, setPhoneNumberLocal] = useState(
                  phoneNumber || ""
                );

                const [nameLocal, setNameLocal] = useState(name);

                const error = Boolean(
                  phoneNumberLocal && !team.isValidPhoneNumber(phoneNumberLocal)
                );

                return (
                  <>
                    <DialogContent>
                      <Stack spacing={3}>
                        <Stack spacing={1}>
                          <Typography>Name</Typography>
                          <TextField
                            value={name}
                            onChange={({ target: { value } }) => {
                              setNameLocal(value);
                            }}
                            sx={{ width: "100%" }}
                          />
                        </Stack>
                        <Stack spacing={1}>
                          <Typography>Phone Number</Typography>
                          <TextField
                            error={error}
                            helperText={
                              error
                                ? "Must be a valid phone number"
                                : "Input phone number e.g. XXX-XXX-XXXX"
                            }
                            value={phoneNumberLocal}
                            onChange={({ target: { value } }) =>
                              setPhoneNumberLocal(value)
                            }
                            sx={{ width: "100%" }}
                          />
                        </Stack>
                      </Stack>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setState((state) => ({
                            ...state,
                            name: nameLocal,
                            phoneNumber: phoneNumberLocal,
                          }));
                          dialog.toggle();
                        }}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </>
                );
              },
            });
            dialog.toggle();
          }}
        />
      </List>
    </>
  );
});
