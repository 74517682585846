import { observer } from "mobx-react-lite";
import { Snackbar } from "@mui/material";

import { useStore } from "../../mobx";

export default observer(function SnackbarComponent() {
  const { snackbar } = useStore();

  return (
    <Snackbar
      message={snackbar.message}
      open={snackbar.open}
      onClose={() => {
        snackbar.toggle();
      }}
      //
      autoHideDuration={5e3}
      TransitionProps={{
        onExited: () => {
          snackbar.set.message("");
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      ContentProps={{
        sx: {
          borderRadius: 20,
          color: "white",
          bgcolor: "grey.900",
          textAlign: "center",
        },
      }}
      sx={{
        "& .MuiSnackbarContent-message": {
          width: "100%",
        },
      }}
    />
  );
});
