import { CardMedia, Fade, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useStore } from "../../mobx";

import QRCodeStyling from "qr-code-styling";

export default observer(function QR() {
  const { team } = useStore();
  const [src, setSrc] = useState("");
  //   const error = name && !team.isValidPhoneNumber(name);

  const link = `${window.location.origin}/report?teamId=${team.id}`;

  useEffect(() => {
    if (team.id) {
      const qrCode = new QRCodeStyling({
        data: link,
        type: "canvas",
        width: 1e3,
        height: 1e3,
        margin: 1,
        image: "/watchdog-grey.png",
        imageOptions: {
          margin: 0,
          imageSize: 0.1,
        },
        dotsOptions: {
          type: "extra-rounded",
        },
        backgroundOptions: {
          gradient: {
            type: "linear", // The type of gradient (linear or radial)
            rotation: 45, // The angle of rotation in degrees (0-360)
            colorStops: [
              { offset: 0, color: "orange" },
              { offset: 1, color: "violet" },
              //   { offset: 2, color: "cyan" },
            ],
          },
        },
        cornersSquareOptions: {
          type: "extra-rounded",
        },
      });

      qrCode.getRawData("jpeg").then((data) => {
        const reader = new FileReader();
        reader.onload = function (event) {
          const base64String = event.target.result.split(",")[1]; // Extract the base64 part

          const src = `data:image/jpeg;base64,${base64String}`;
          setSrc(src);
        };
        reader.readAsDataURL(data);
      });
    }
  }, [link, team.id]);

  return (
    <>
      <Stack alignItems="center">
        {!src ? (
          <CircularProgress />
        ) : (
          <Fade in={Boolean(src)}>
            <CardMedia
              id="qr-code-image"
              component="img"
              src={src}
              sx={{ borderRadius: "2%", display: "flex", width: "100%" }}
            />
          </Fade>
        )}
      </Stack>
    </>
  );
});
