import { observer } from "mobx-react-lite";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Grow,
} from "@mui/material";
import {
  ArticleOutlined as ReportsIcon,
  Home as HomeIcon,
  People,
  QrCode,
  Settings,
  Logout,
  ChevronLeft,
  Money,
} from "@mui/icons-material";
import { store } from "../mobx";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import WatchdogText from "./WatchdogText";
import { alpha, Box, Stack } from "@mui/system";
import { useLocation } from "react-router";
import { setSubscribeDialog } from "./Dialog/setSubscribeDialog";

// import { Firebase } from '../../services/firebase';

export default observer((props) => {
  const {
    user,
    drawer,
    team,
    mediaBreakpoint: { isPhone },
  } = store;
  const navigate = useNavigate();

  const location = useLocation();

  const [items, setItems] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [bottomItems, setBottomItems] = useState([]);

  useEffect(() => {
    const defaultItem = {
      name: "Home",
      path: "/home",
      Icon: HomeIcon,
    };

    const items = [
      {
        name: "QR Code",
        path: "/qr",
        Icon: QrCode,
      },
      {
        name: "Reports",
        path: "/reportDashboard",
        Icon: ReportsIcon,
      },
    ];

    const additionalItems = [
      {
        name: "Team",
        path: "/team",
        Icon: People,
      },
      {
        name: "Account",
        path: "/account",
        Icon: Settings,
      },
    ];

    const bottomItems = [
      ...(!user.subscribed
        ? [
            {
              name: "Subscribe",
              onClick: setSubscribeDialog,
              props: {
                sx: (theme) => ({
                  // border: "1px solid red",
                  // borderRadius: 4,
                  boxShadow: `0px 0px 2px 0.25px ${theme.palette.secondary.main}`,
                  color: "secondary.light",

                  "& svg": { color: "secondary.light" },
                }),
              },
              Icon: Money,
            },
          ]
        : []),
      {
        name: "Logout",

        onClick: async () => {
          await user.signOut();

          if (isPhone) {
            drawer.toggle();
          }

          navigate("/");
        },
        Icon: Logout,
      },
    ];

    setItems([defaultItem, ...items]);
    setAdditionalItems(additionalItems);
    setBottomItems(bottomItems);

    // if (team.name) {
    //   setItems([defaultItem, ...items]);
    //   setAdditionalItems(additionalItems);
    //   setBottomItems(bottomItems);

    //   return;
    // }
    setItems([defaultItem]);
  }, [user, user.subscribed, drawer, isPhone, navigate, team.name]);

  // useEffect(() => {
  //   setSubscribeDialog();
  // }, [setSubscribeDialog]);

  const drawerOpenOrPhone = isPhone || drawer.open;

  return (
    <Drawer
      ModalProps={{
        // internal bug with mui causes the portal component to remain mounted even if keepMounted is set to false
        // suggests the cleanup sequence has an edge case that fails to clean it up... Probably because we are switching variants
        disablePortal: true,
      }}
      variant={isPhone ? "temporary" : "permanent"}
      anchor={"left"}
      open={drawer.open}
      onClose={drawer.toggle}
      PaperProps={{
        sx: {
          width: drawerOpenOrPhone ? 300 : 56,
          transition: isPhone ? undefined : "all 300ms",
          overflowX: "hidden",
          "& .MuiListItem-root": {
            height: 48,
          },
        },
      }}
    >
      <>
        <Stack
          direction="row"
          pl={drawer.open ? 2 : undefined}
          py={1}
          width={drawer.open || isPhone ? undefined : 56}
          alignItems="center"
          justifyContent={drawer.open || isPhone ? "space-between" : "center"}
        >
          {(drawer.open || isPhone) && (
            <Grow in={true}>
              <Box onClick={() => navigate("/")}>
                <WatchdogText
                  textAlign="left"
                  pb={0.25}
                  // disableFullText={isPhone}
                />
              </Box>
            </Grow>
          )}

          <IconButton onClick={drawer.toggle}>
            <ChevronLeft
              sx={{
                transition: "all 300ms",
                ...(!drawer.open ? { transform: "scaleX(-1)" } : undefined),
              }}
            />
          </IconButton>
        </Stack>
        <Divider />
      </>

      <List sx={{ py: 0 }}>
        {items.map(({ name, path, Icon }) => (
          <ListItem
            key={name}
            button
            onClick={() => {
              navigate(path);
              if (isPhone) {
                drawer.toggle();
              }
            }}
            sx={(theme) => ({
              bgcolor:
                location.pathname === path
                  ? alpha(theme.palette.primary.main, 0.1)
                  : undefined,
            })}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            {(drawer.open || isPhone) && <ListItemText primary={name} />}
          </ListItem>
        ))}
      </List>

      <Divider />
      <List sx={{ pt: 0 }}>
        {additionalItems.map(({ name, path, Icon }) => {
          return (
            <ListItem
              button
              key={name}
              onClick={() => {
                navigate(path);
                if (isPhone) {
                  drawer.toggle();
                }
              }}
              sx={(theme) => ({
                bgcolor:
                  location.pathname === path
                    ? alpha(theme.palette.primary.main, 0.1)
                    : undefined,
              })}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              {(drawer.open || isPhone) && <ListItemText primary={name} />}
            </ListItem>
          );
        })}
      </List>
      <Stack height={"100%"} direction="column" justifyContent="flex-end">
        <List sx={{ pb: 0 }}>
          {bottomItems.map(({ Icon, name, onClick, props }, index) => {
            return (
              <ListItem key={index} button {...props} onClick={onClick}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                {(drawer.open || isPhone) && <ListItemText primary={name} />}
              </ListItem>
            );
          })}
        </List>
      </Stack>
    </Drawer>
  );
});
