import { Box, Button, CardMedia, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/system";
import { KeyboardArrowDown } from "@mui/icons-material";

import { store } from "../../mobx";

const BG_COLOR = "#080d17";

export const ValueProp = observer(function ValueProp() {
  const navigate = useNavigate();

  const { isPhone } = store.mediaBreakpoint;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      height="100%"
      sx={{
        position: "relative",
      }}
    >
      <Stack
        spacing={isPhone ? 3 : 4}
        justifyContent="center"
        alignItems="center"
        sx={{
          px: 2,

          height: "100%",
          width: isPhone ? undefined : "45vw",
          position: isPhone ? "absolute" : "relative",
          bgcolor: isPhone ? `${BG_COLOR}cc` : undefined,
        }}
      >
        <Stack alignItems="center">
          <Typography
            variant="h2"
            textAlign="center"
            sx={(theme) => ({
              fontFamily: "PermanentMarker",
              fontSize: isPhone ? 44 : 56,
              mb: isPhone ? undefined : -0.5,
              lineHeight: "36px",
              WebkitTextStroke: 2,
              WebkitTextStrokeColor: theme.palette.primary.main,
              color: "black",
            })}
          >
            Watchdog
          </Typography>
        </Stack>
        <Stack justifyContent="center" alignItems="center" spacing={4}>
          <Box>
            <Typography
              variant="h2"
              textAlign="center"
              sx={(theme) => ({
                fontFamily: "PermanentMarker",
                fontSize: isPhone ? 36 : 48,
                WebkitTextStroke: 2,
                WebkitTextStrokeColor: theme.palette.secondary.main,
                color: "black",
              })}
            >
              QR based safety for your patrons, at the tap of a button
            </Typography>
          </Box>

          {/* <Box /> */}
          <Button
            variant="contained"
            sx={(theme) => ({
              fontFamily: "PermanentMarker",
              fontSize: isPhone ? 24 : 32,
              WebkitTextStroke: 1,
              WebkitTextStrokeColor: "black",
              letterSpacing: 4,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "secondary.main",
            })}
            onClick={() => navigate("/signup")}
          >
            Sign up
          </Button>
        </Stack>
      </Stack>
      <Box>
        <CardMedia
          component="img"
          src="/landing-page-background.png"
          sx={{
            ...(isPhone
              ? {
                  height: "100%",
                  width: "100%",
                  // width: 1920 / 3,
                  // pt: 0.5,
                  objectFit: "cover",
                  objectPosition: "top",
                }
              : {
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "left",
                  // pl: 2,
                  // pb: 2,
                  // boxShadow: `0 0 16px 16px ${BG_COLOR} inset`,
                  // boxShadow: `0 0 16px 16px #fff1`,
                }),
          }}
        />
      </Box>

      <KeyboardArrowDown
        sx={{
          position: "absolute",
          left: "calc(50% - 30px)",
          bottom: isPhone ? 56 : 8,
          width: 60,
          height: 60,
          // transform: "rotate(-90deg)",
          color: "secondary.light",
          borderRadius: 15,
          WebkitTapHighlightColor: "transparent",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "#fff1",
          },
        }}
        onClick={() => {
          const section2 = document.getElementById("section-2");

          section2.scrollIntoView({ behavior: "smooth" });
        }}
      />
    </Stack>
  );
});
