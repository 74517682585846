import { createContext, useContext } from "react";
import { observer as originalObserver } from "mobx-react-lite";
import Team from "./team";
import User from "./user";
import Reporting from "./reporting";
import Drawer from "./drawer";
import Dialog from "./dialog";
import Snackbar from "./snackbar";
import AppBar from "./appbar";
import MediaBreakpoint from "./mediaBreakpoint";
import GlobalFx from "./globalFx";
//
const storeContext = createContext();
//
export const StoreProvider = ({ children }) => (
  <storeContext.Provider value={store}>{children}</storeContext.Provider>
);

class GlobalStore {
  constructor() {
    this.user = new User(this);
    this.team = new Team(this);
    this.reporting = new Reporting(this);
    this.drawer = new Drawer(this);
    this.dialog = new Dialog(this);
    this.snackbar = new Snackbar(this);
    this.appbar = new AppBar(this);
    this.mediaBreakpoint = new MediaBreakpoint(this);
    this.globalFx = new GlobalFx(this);
  }
}

export const store = new GlobalStore();

export const useStore = () => useContext(storeContext);

export const observer = originalObserver;
