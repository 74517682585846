import { CheckOutlined } from "@mui/icons-material";
import { Avatar, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";

import { useStore } from "../../mobx";

export default observer(function AwaitContact() {
  const { reporting } = useStore();

  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <>
      <Stack spacing={3} alignItems="center">
        {reporting.loading ? (
          <>
            <Typography>Contacting Team Member </Typography>
            <CircularProgress />
          </>
        ) : (
          <>
            <Avatar
              sx={{
                borderRadius: 80,
                border: "1px solid green",
                bgcolor: "transparent",
              }}
            >
              <CheckOutlined
                sx={{
                  color: "green",
                }}
              />
            </Avatar>
          </>
        )}
      </Stack>
    </>
  );
});
