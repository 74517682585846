import { observer } from "mobx-react-lite";

import { store } from "../../mobx";

import { SubsectionStack } from "./SubsectionStack";

export const Video = observer(function Video({
  title = "How it works",
  spacing = 8,
}) {
  const { isPhone } = store.mediaBreakpoint;

  return (
    <SubsectionStack title={title} spacing={spacing}>
      <iframe
        width={isPhone ? document.body.clientWidth - 32 : 800}
        height={
          isPhone ? document.body.clientWidth * (10 / 16) - 32 * (16 / 10) : 500
        }
        style={{
          borderRadius: 32,
        }}
        src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=DVWM8s08pxWo0j_q"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </SubsectionStack>
  );
});
