// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  doc,
  where,
  onSnapshot,
  query,
  arrayRemove,
  documentId,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAAY2yKDeI6mnWJZLSwwPLsIR9Cy8mZGLg",
  authDomain: "watchdog-6de84.firebaseapp.com",
  projectId: "watchdog-6de84",
  storageBucket: "watchdog-6de84.appspot.com",
  messagingSenderId: "889944848556",
  appId: "1:889944848556:web:2e3b78072efb6d2d470709",
  measurementId: "G-NV4EZH8LRP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);

const db = getFirestore(app);

export const firestore = {
  collection: {
    add: ({ path, data }) => addDoc(collection(db, path), data),
    get: {
      docs: (query) => getDocs(query),
    },
  },
  doc: {
    set: ({ path, data }, options) => setDoc(doc(db, path), data, options),
    update: ({ path, data }) => updateDoc(doc(db, path), data),
    removeItemFromArray: ({ path, field, value }) =>
      updateDoc(doc(db, path), {
        [field]: arrayRemove(value),
      }),
    get: ({ path }) => getDoc(doc(db, path)),
    delete: ({ path }) => deleteDoc(doc(db, path)),
  },
};

const ogCollection = collection;

const ogDoc = doc;

export {
  query,
  collection,
  where,
  ogCollection,
  ogDoc,
  onSnapshot,
  db,
  auth,
  documentId,
};
