import { getCookie } from "../utilities/cookies";

async function fetchFromApiServer(url, options) {
  const response = await fetch(url, {
    ...options,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${store.user.session.accessToken}`,
      "Custom-Authorization": getCookie("firebaseJwt"),
      ...options?.headers,
    },
  });

  return response;
}

export { fetchFromApiServer };
