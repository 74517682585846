import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  CircularProgress,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { observer } from "mobx-react-lite";
import { store } from "../../mobx";
import Page from "./index";

export default observer(function SingleCardPage({
  containerProps,
  cardProps,
  cardHeaderProps,
  cardMediaProps,
  cardContentProps,
  cardContentChildren,
  Action,
  loading = false,
}) {
  const {
    team,
    mediaBreakpoint: { isPhone },
  } = store;

  return (
    <Page {...containerProps}>
      {team.loaded && !loading ? (
        <>
          <Card
            {...cardProps}
            sx={(theme) => ({
              // overflow: "overlay",
              width: "100%",
              height: "100%",
              // bgcolor: "surface.600",
              transition: "all 300ms",

              [theme.breakpoints.down("md")]: {
                height: isPhone ? "calc(100vh - 56px)" : "100%",
                mt: 7,
                overflowY: "overlay",
              },

              [theme.breakpoints.up("md")]: {
                px: "20%",
              },

              [theme.breakpoints.up("lg")]: {
                px: "30%",
                ...cardProps?.sxDesktop,
              },
              ...cardProps?.sx,
            })}
          >
            <CardHeader
              {...cardHeaderProps}
              sx={
                {
                  //
                  // pb: 0,
                }
              }
            />

            <CardContent {...cardContentProps}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 0,
                }}
              >
                {cardMediaProps && <CardMedia {...cardMediaProps} />}
              </Box>
              {cardContentChildren}
            </CardContent>
            <CardActions>{Action}</CardActions>
          </Card>
        </>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", mt: 17 }}
        >
          <CircularProgress />
        </Stack>
      )}
    </Page>
  );
});
