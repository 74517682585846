import { Box } from "@mui/system";
import Dialog from "./components/Dialog";
import Snackbar from "./components/Snackbar";
import { useSetDevice } from "./hooks/useSetDevice";
import RouteContainer from "./RouteContainer";

function App() {
  useGlobalHooks();

  return (
    <Box>
      <RouteContainer />

      <Dialog />
      <Snackbar />

      {/* Adding local styles for the animation */}
      <style>{`
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </Box>
  );
}

function useGlobalHooks() {
  useSetDevice();
}

export default App;
