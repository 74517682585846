import { Typography, CardMedia } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";

import { APP_NAME } from "../../constants";

export default observer(function SetNotificationStyle() {
  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <>
      <Stack alignItems="center" spacing={2}>
        <Typography>You're now ready to start using {APP_NAME}!</Typography>
        <CardMedia
          component="img"
          src="https://media.tenor.com/iNpeACeWU2UAAAAC/baby-child.gif"
          sx={{ borderRadius: 2 }}
        />
        <Typography textAlign="center">
          Simply place the QR codes in your establishment, and we'll take care
          of the rest!{" "}
        </Typography>
      </Stack>
    </>
  );
});
