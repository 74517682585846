import { Edit } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";

import { observer } from "mobx-react-lite";
import { TeamMemberListItemTeamOwner } from "../../components/Lists/EditTeamMembersList";

import { useStore } from "../../mobx";
import { stringToHsl } from "../../utilities";

export default observer(function FinalizeTeamInfo() {
  const { team } = useStore();

  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <>
      <Typography>Company</Typography>
      <Divider />
      <List>
        <ListItem sx={{ mb: 2 }}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: stringToHsl(team.name) }}>
              {team.name[0]}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={team.name} secondary={team.location} />
          <ListItemSecondaryAction>
            <IconButton>
              <Edit />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Typography>Team members</Typography>
      <Divider />

      <List>
        {team.members.map((member, index) => {
          return (
            <TeamMemberListItemTeamOwner
              key={`${member.name}-${index}`}
              index={index}
              member={member}
            />
          );
        })}
      </List>
    </>
  );
});
