function getCookiesObject() {
  const components = document.cookie.split(";");

  const cookiesObject = {};

  for (const component of components) {
    const [key, value] = component.split("=");

    cookiesObject[key.trim()] = value;
  }

  return cookiesObject;
}

export function setCookie({ key, value, path = "/" }) {
  const cookieObject = {
    [key]: encodeURIComponent(value),
    path,
    samesite: "lax",
    domain: window.location.hostname,
  };

  const cookieString = Object.entries(cookieObject)
    .map(([key, value]) => `${key}=${value}`)
    .join(";");

  document.cookie = cookieString;
}

export function getCookie(key) {
  const cookiesObject = getCookiesObject();

  const value = cookiesObject[key];

  return value;
}
