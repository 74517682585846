import { Edit, Save } from "@mui/icons-material";
import { IconButton, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { ClickAwayListener } from "@mui/base";

import { observer } from "mobx-react-lite";

import { useState } from "react";

export default observer(function EditableField({
  label,
  value,
  onSave = async () => undefined,
}) {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(value);

  const handleSave = async () => {
    setEditing(false);
    await onSave(text);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setEditing(false);
        setText(value);
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2, py: 1 }}
      >
        <>
          <Box sx={{ width: "100%" }}>
            {!editing ? (
              <Stack direction="row" alignItems="center">
                <Typography>
                  <b>{label}</b>
                </Typography>
                <Typography>: {value}</Typography>
              </Stack>
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                // justifyContent="space-between"
                sx={{
                  width: "100%",
                  //
                  //   border: "1px solid red",
                }}
              >
                <Typography sx={{ whiteSpace: "pre" }}>
                  <b>{label}</b>:{" "}
                </Typography>
                <TextField
                  autoFocus
                  value={text}
                  inputProps={{
                    sx: {
                      letterSpacing: "0.13px",
                    },
                  }}
                  onChange={({ target: { value } }) => setText(value)}
                  variant="standard"
                  sx={{
                    width: "100%",
                    // ml: "4px",
                    // //
                    pr: 2,
                    // py: 0,
                  }}
                  onKeyDown={async (event) => {
                    if (event.key === "Enter") {
                      // Add your desired functionality here when the Enter key is pressed
                      handleSave();
                    }
                  }}
                />
              </Stack>
            )}
          </Box>

          <Box>
            {!editing ? (
              <IconButton onClick={() => setEditing(true)}>
                <Edit />
              </IconButton>
            ) : (
              <IconButton onClick={handleSave}>
                <Save />
              </IconButton>
            )}
          </Box>
        </>
      </Stack>
    </ClickAwayListener>
  );
});
