import { useLocation } from "react-router";
import LandingPage from "./views/landingPage";
import Home from "./views/home";
import SignUp from "./views/signup";
import Report from "./views/report";
import ReportsTable from "./views/reportsTable";
import Account from "./views/account";
import QR from "./views/qr";
import Team from "./views/team";
import Onboard from "./views/onboarding";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import { Routes, Route } from "react-router-dom";

import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { store } from "./mobx";
import { useEffect } from "react";
import Snowfall from "react-snowfall";
import { Fade } from "@mui/material";

function RouteContainer() {
  const { isPhone } = store.mediaBreakpoint;
  const { globalFx } = store;

  const location = useLocation();

  useEffect(() => {
    if (isPhone === false) {
      store.drawer.set.open(true);
    } else if (isPhone === true) {
      store.drawer.set.open(false);
    }
  }, [isPhone]);

  const onRootPath = location.pathname === "/";

  return (
    <Box
      pl={
        !onRootPath && !isPhone && store.drawer.open
          ? "300px"
          : !onRootPath && !isPhone
          ? "56px"
          : undefined
      }
      height="100vh"
      width="100vw"
      sx={(theme) => {
        return {
          // overflow: "overlay",
          transition: theme.transitions.create("all"),
        };
      }}
    >
      <Fade in={globalFx.snowing}>
        <Box>
          <Snowfall
            snowflakeCount={400}
            speed={[1.0, 5.0]}
            color="pink"
            style={{
              zIndex: 1.3e3,
            }}
          />
        </Box>
      </Fade>
      {!onRootPath && <Drawer />}
      {/* {(isPhone && <AppBar />) ||
        (!isPhone && !store.drawer.open && <AppBar />)} */}
      {isPhone && <AppBar />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/report" element={<Report />} />
        <Route path="/reportDashboard" element={<ReportsTable />} />
        <Route path="/account" element={<Account />} />
        <Route path="/qr" element={<QR />} />
        <Route path="/team" element={<Team />} />
        <Route path="/onboarding" element={<Onboard />} />
      </Routes>
    </Box>
  );
}

export default observer(RouteContainer);
