import { Box } from "@mui/system";

export function DotGridCluster({
  alternate,
  reverseVertical,
  number = 1,
  direction,
  dotSize,
  dotColor = "white",
  animationOffset = 0,
  speedMs,
  speedOffset = 0,
  sx,
}) {
  const items = Array(number).fill(null);

  return (
    <>
      {items.map((_, index) => {
        let adjustedSpeed = speedMs - index * speedOffset;

        if (reverseVertical) {
          adjustedSpeed = speedMs - (items.length - 1 - index) * speedOffset;
        }

        let adjustedDirection = direction;

        if (alternate && index % 2 !== 1) {
          if (adjustedDirection === "left") {
            adjustedDirection = "right";
          } else if (adjustedDirection === "right") {
            adjustedDirection = "left";
          }
        }

        return (
          <DotGridBorder
            key={index}
            speedOffset={speedOffset}
            dotSize={dotSize}
            dotSpacing={dotSize * 2}
            dotColor={dotColor}
            animationOffset={animationOffset}
            direction={adjustedDirection}
            speedMs={adjustedSpeed}
            sx={{
              height: sx?.height ? sx?.height : dotSize * 2,
              ...sx,
            }}
          />
        );
      })}
    </>
  );
}

export default function DotGridBorder({
  dotSize,
  dotSpacing,
  dotColor,
  animationOffset,
  direction,
  speedMs,
  sx,
}) {
  return (
    <Box
      sx={constructDotGridThemeFunction({
        dotSize,
        dotSpacing,
        dotColor,
        animationOffset,
        direction,
        speedMs,
        sx: { width: "100vw", height: 60, ...sx },
      })}
    />
  );
}

const constructDotGridThemeFunction = ({
  dotSize = 15,
  dotSpacing,
  dotColor,
  animationOffset = 0,
  direction = "right",
  speedMs = 700,
  sx,
}) => {
  const halfSize = dotSize / 2;

  dotSpacing ??= dotSize;

  const sign = direction === "right" ? "" : "-";

  return function (theme) {
    return {
      backgroundImage: `radial-gradient(circle at ${halfSize}px ${halfSize}px, ${dotColor} ${halfSize}px, transparent ${halfSize}px)`,
      backgroundSize: `${dotSpacing}px ${dotSpacing}px`,
      animation: `
          moveBackground${direction}${speedMs} ${speedMs}ms linear infinite;
          @keyframes moveBackground${direction}${speedMs} {
            0% {
              background-position: ${animationOffset}px 0px;
            }
            100% {
              background-position: ${sign}${dotSpacing + animationOffset}px 0px;
            }
          }
        `,
      ...sx,
    };
  };
};
