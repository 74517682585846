import React, { useEffect, useState } from "react";
import { Typography, Paper } from "@mui/material";

import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  DragDropProvider,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  EditingState,
  IntegratedEditing,
  ViewState,
} from "@devexpress/dx-react-scheduler";
import moment from "moment";

import { observer } from "mobx-react-lite";

const initialSchedulerData = [
  {
    id: 0,
    startDate: moment("2023-08-21T01:00"),
    endDate: moment("2023-08-21T17:00"),
    title: "Block",
  },
  {
    id: 1,
    startDate: moment("2023-08-22T09:00"),
    endDate: moment("2023-08-22T17:00"),
    title: "Block",
  },
  {
    id: 2,
    startDate: moment("2023-08-23T09:00"),
    endDate: moment("2023-08-23T17:00"),
    title: "Block",
  },
  {
    id: 3,
    startDate: moment("2023-08-24T09:00"),
    endDate: moment("2023-08-24T17:00"),
    title: "Block",
  },
  {
    id: 4,
    startDate: moment("2023-08-25T09:00"),
    endDate: moment("2023-08-25T17:00"),
    title: "Block",
  },
];

export default observer(function OnboardingSetNotifications({ onChange }) {
  const [data, setData] = useState(initialSchedulerData);

  const handleCommitChanges = ({ added, changed, deleted }) => {
    if (added) {
      const startingId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
      setData([...data, { id: startingId, ...added }]);
    }
    if (changed) {
      setData(
        data.map((appointment) =>
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment
        )
      );
    }
    if (deleted !== undefined) {
      setData(data.filter((appointment) => appointment.id !== deleted));
    }
  };

  useEffect(() => {
    onChange({
      availability: data.map((block) => ({
        // the library we're implementing is buggy af, this recasting is required to ensure compat between ISO strings and actual Moment() objects
        startDate: moment(block.startDate).toDate(),
        endDate: moment(block.endDate).toDate(),
      })),
    });
  }, [onChange, data]);

  return (
    <>
      <Typography paragraph>
        This will make sure we only send safety alerts you when you're working
      </Typography>

      <Paper
        sx={(theme) => ({
          mt: 1,
          maxHeight: 600,
          [theme.breakpoints.down("md")]: {
            maxHeight: 300,
          },
          overflowY: "scroll",
        })}
      >
        <Scheduler data={data}>
          <ViewState />
          <EditingState onCommitChanges={handleCommitChanges} />

          <IntegratedEditing />

          <WeekView />

          <Appointments />

          <AppointmentTooltip showDeleteButton />

          {/* <AppointmentForm /> */}
          <AppointmentForm
            visible={false}
            onAppointmentDataChange={({ startDate, endDate }) => {
              setData([
                ...data,
                {
                  id: data.length,
                  title: "Block",
                  startDate: moment(startDate),
                  endDate: moment(endDate),
                },
              ]);
            }}
            overlayComponent={(props) => <FakeOverlayComponent {...props} />}
            dateEditorComponent={(props) => <FakeOverlayComponent {...props} />}
          />

          <DragDropProvider />
        </Scheduler>
      </Paper>
    </>
  );
});

function FakeOverlayComponent({ ...props }) {
  return null;
}
