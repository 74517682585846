import { makeAutoObservable } from "mobx";

//
export default class Snackbar {
  constructor(_) {
    this._ = _;

    this.reset();

    makeAutoObservable(this);
  }

  reset() {
    for (const setter of Object.values(this.set)) {
      setter();
    }
  }

  set = {
    open: (open = false) => {
      this.open = open;
    },
    message: (message = "") => {
      this.message = message;

      if (message) {
        this.set.open(true);
      }
    },
  };

  toggle = () => {
    this.set.open(!this.open);
  };
}
