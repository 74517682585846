import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { observer } from "mobx-react-lite";

export default observer(function OnboardingWelcome({
  state: { name, teamName },
}) {
  //   const error = name && !team.isValidPhoneNumber(name);

  return (
    <Stack spacing={4}>
      <Typography>Welcome to Watch Dog, {name}!</Typography>

      <Typography>
        Your employer <b>{teamName}</b> has added you to Watch Dog notification
        services to receive safety alerts from patrons.
      </Typography>

      <Typography>Setup's quick, it's just three steps!</Typography>
    </Stack>
  );
});
